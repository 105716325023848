import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useEffect, useRef } from "react";

export type Page<T> = {
  items: T[];
  count?: number;
};

type FetchPageFn<T> = (pageNumber: number) => Promise<Page<T>>;

/**
 * Wrapper around useInfiniteQuery to easily connect with
 * AgGrid's infinite data source.
 *
 * @param queryKey react-query's query key
 * @param fetchPage function to fetch a page of data
 * @param onRefetch this function is called after refetching
 */
export function usePagedQuery<T>(
  queryKey: string[],
  fetchPage: FetchPageFn<T>,
  onRefetch?: () => void
) {
  const queryClient = useQueryClient();
  const prevIsRefetchingRef = useRef(false);
  const { data, fetchNextPage, refetch, isRefetching, error } =
    useInfiniteQuery({
      initialPageParam: 1,
      queryKey: queryKey,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      queryFn: async (params) => {
        const page = await fetchPage(params.pageParam);

        return page;
      },
      getNextPageParam: (_, pages) => pages.length + 1,
    });
  const count = data?.pages.at(-1)?.count ?? undefined;

  // ONLY calling onRefetch after refetching and skipping the initial render
  useEffect(() => {
    if (!isRefetching && prevIsRefetchingRef.current && onRefetch) {
      onRefetch();
    }

    prevIsRefetchingRef.current = isRefetching;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetching]);

  const getPage = useCallback(
    async (pageNumber: number) => {
      const data = queryClient.getQueryData<InfiniteData<Page<T>>>(queryKey);
      let page = data?.pages?.[pageNumber];

      if (!page) {
        const res = await fetchNextPage();

        page = res.data?.pages[pageNumber];
      }

      if (page) {
        return page;
      }

      throw new Error("Page not found");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...queryKey]
  );

  return {
    getPage,
    count,
    refetch,
    error,
  };
}
