import { useQuery } from "@tanstack/react-query";

import { RevisionResponse } from "@revv/data";

import { useStateContext } from "../context";

const baseUrl = "/v1/revisions";

export function useRevision(id: string) {
  const { apiClient } = useStateContext();

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["revision", id],
    queryFn: async () => {
      const response = await apiClient.get<RevisionResponse>(
        `${baseUrl}/${id}`
      );

      return response.data;
    },
  });

  return {
    revision: data?.revision,
    error,
    isLoading,
  };
}
