import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  EditSensorEntry,
  EditSensorResponse,
  ListSensorResponse,
} from "@revv/data";

import { useStateContext } from "../context";
import { usePagedQuery } from "../usePagedQuery";

const pageSize = 100;
const baseUrl = "/v1/sensors";

export type SensorFilterParams = {
  carId: string;
  name: string;
  makeId: string;
  model: string[];
  year: string[];
  location: string[];
  sensorCategory: string;
};

export type SensorParams = SensorFilterParams & {
  sortBy: string;
  sortOrder: "asc" | "desc" | "";
} & { variant?: string };

export function useSensors(params: SensorParams, onRefresh?: () => void) {
  const { apiClient } = useStateContext();
  const queryKey = ["sensors", JSON.stringify(params)];

  const { getPage, refetch, count, error } = usePagedQuery(
    queryKey,
    async (pageNumber: number) => {
      const res = await fetchSensors(apiClient, params, pageNumber);

      return {
        items: res.sensors,
        count: res.count,
      };
    },
    onRefresh
  );

  const updateMutation = useMutation({
    mutationFn: async (updates: EditSensorEntry[]) => {
      const queryParams = convertParams({
        ...params,
        sortBy: "",
        sortOrder: "",
      });

      if (params.variant) {
        queryParams.append("variant", params.variant);
      }
      const res = await apiClient.patch<EditSensorResponse>(baseUrl, updates, {
        params: queryParams,
      });

      return res.data.message;
    },
    onSuccess: async () => {
      await refetch();
    },
  });

  return {
    getPage,
    count,
    pageSize,
    error,
    updateMutation: {
      mutateAsync: updateMutation.mutateAsync,
      isPending: updateMutation.isPending,
      error: updateMutation.error || undefined,
    },
  };
}

async function fetchSensors(
  apiClient: AxiosInstance,
  params: SensorParams,
  pageNumber: number
) {
  const queryParams = convertParams(params);

  queryParams.append("pageNumber", pageNumber.toString());
  queryParams.append("pageSize", pageSize.toString());

  if (params.variant) {
    queryParams.append("variant", params.variant);
  }
  const res = await apiClient.get<ListSensorResponse>(baseUrl, {
    params: queryParams,
  });

  return res.data;
}

function convertParams(params: SensorParams) {
  const queryParams = new URLSearchParams();

  queryParams.append("carId.equals", params.carId);
  queryParams.append("name.contains", params.name);
  queryParams.append("makeId.equals", params.makeId);

  if (params.model) {
    params.model.forEach((model) => queryParams.append("model.equals", model));
  }

  if (params.year) {
    params.year.forEach((year) => queryParams.append("year.equals", year));
  }

  if (params.location) {
    params.location.forEach((location) =>
      queryParams.append("location.equals", location)
    );
  }

  if (params.sensorCategory) {
    queryParams.append("sensorCategory.equals", params.sensorCategory);
  }

  queryParams.append("sortBy", params.sortBy);
  queryParams.append("order", params.sortOrder);

  const fields = [...queryParams.keys()];

  for (const key of fields) {
    if (!queryParams.get(key)) {
      queryParams.delete(key);
    }
  }

  return queryParams;
}
