export enum RevisionStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum RevisionTarget {
  SENSORS = "SENSORS",
  MAPPINGS = "MAPPINGS",
  KEYWORDS = "KEYWORDS",
}

export enum RevisionOperation {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export type Revision = {
  id: number;
  status: RevisionStatus;
  target: RevisionTarget;
  operation: RevisionOperation;
  data: Record<string, unknown>;
  description: string;
  createdAt: string;
  createdBy: string;
  reviewedAt?: string;
  reviewedBy?: string;
};

export type RevisionsResponse = {
  revisions: Revision[];
  count: number;
};

export type RevisionResponse = {
  revision: Revision;
};
