import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";

import { UserPermissions } from "@revv/data";

import { useStateContext } from "./context";

const QUERY_KEY = ["userPermissions"];

type UsePermissionsResponse = {
  permissions: typeof UserPermissions;
};

export function usePermissions() {
  const { apiClient } = useStateContext();
  const { data } = useQuery({
    queryKey: QUERY_KEY,
    // not reloading for at least 10 hours
    staleTime: 10 * 60 * 60 * 1000,
    queryFn: async () => {
      try {
        const res =
          await apiClient.get<UsePermissionsResponse>("/v1/permissions");
        return res.data.permissions;
      } catch (err) {
        Sentry.captureException(err);
        throw err;
      }
    },
  });

  return data;
}
