import { useQuery } from "@tanstack/react-query";

import { useStateContext } from "../context";

const baseUrl = "/v1/audit/variants?sortBy=createdAt&order=desc";

export function useVariants() {
  const { apiClient } = useStateContext();

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["sensor-variants"],
    queryFn: async () => {
      const response = await apiClient.get<Record<string, unknown>>(baseUrl);
      return response.data.variants;
    },
  });

  return {
    variants: data as { id: number; name: string }[],
    error,
    isLoading,
  };
}
