import { useQuery } from "@tanstack/react-query";

import { useStateContext } from "../context";

const baseUrl = "/v1/audit?sortBy=editedAt&order=desc";

export function useAudit() {
  const { apiClient } = useStateContext();

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["sensor-audits"],
    queryFn: async () => {
      const response = await apiClient.get<Record<string, unknown>>(baseUrl);
      return response.data.audit;
    },
  });

  return {
    audit: data as {
      id: number;
      editedAt: string;
      status: string;
      data: Record<string, unknown>;
    }[],
    error,
    isLoading,
  };
}
