import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { Shop } from "@revv/data";

import { useStateContext } from "./context";

const BASE_URL = "/v1/shop/getAllShops/";

type ShopsResponse = {
  shops: Shop[];
  nextPageCount: number;
};

export function useShops(
  masterEmail = "",
  pageNumber = 1,
  pageSize = 10,
  filter = ""
) {
  const { apiClient } = useStateContext();

  const { data, refetch, error, isLoading } = useQuery<ShopsResponse, Error>({
    refetchOnWindowFocus: false,
    queryKey: ["shops", masterEmail, pageNumber, pageSize, filter],
    queryFn: async () => {
      try {
        const response = await apiClient.get<ShopsResponse>(
          `${BASE_URL}${masterEmail}?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter}`
        );
        return response.data;
      } catch (error: unknown) {
        const error_ =
          error instanceof AxiosError
            ? new Error(error.response?.data.error)
            : new Error("Something went wrong");
        throw error_;
      }
    },
  });

  return {
    shops: data?.shops,
    nextPageCount: data?.nextPageCount,
    isLoading,
    shopsError: error,
    refetch,
  };
}
